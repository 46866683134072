import React, { useEffect, useState } from "react";
import Loader from "../../Layouts/Loader";
import { getEvents } from "../../Services/Events";
import { EventTable } from "./Components/EventTable";
import { useSelector } from "react-redux";

const Events = () => {
  const [Events, setEvents] = useState();
  const eventTable = useSelector((store) => store.Events);
  const search = eventTable.searchText;
  const page = eventTable.page;
  const page_size = eventTable.sizePerPage;
  const app_type = eventTable.apptype || "";
  const sort_by =
    eventTable.sorting &&
    eventTable.sorting.dataField + " " + eventTable.sorting.order;
  const event_status = eventTable.select;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEvents(search, page, page_size, sort_by, event_status, app_type)
      .then((res) => {
        const updatedEventsData = res.data.data.responseData.map(
          (event, index) => {
            let formatDate = event.event_date.split("/");
            formatDate = [formatDate[2], formatDate[0], formatDate[1]].join(
              "/"
            );
            event.event_sort_date = formatDate;
            event.status =
              event.status === "Ideal" || event.status === "Closed"
                ? event.status === "Ideal"
                  ? "Open"
                  : "Completed"
                : event.status;
            return event;
          }
        );
        setEvents({
          ...res.data,
          data: { ...res.data.data, responseData: updatedEventsData },
        });
        setLoading(false);
      })
      .catch((err) => {
        setEvents(err.response.data);
        setLoading(false);
      });
  }, [search, page, page_size, sort_by, event_status, app_type]);
  return (
    <>
      <div className="page_header ">
        <h2>All Events</h2>
      </div>
      <div className="card p-4 table-card ">
        {/* {Events ? <Table Events={Events} /> : <Loader />} */}
        {Events ? <EventTable Events={Events} loading={loading} /> : <Loader />}
      </div>
    </>
  );
};

export default Events;
