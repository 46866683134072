import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  SizePerPageDropDown,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//import overlayFactory from "react-bootstrap-table2-overlay";
import { headerStyle } from "../../../assets/css/customStyle";
import PayoutModal from "./PayoutModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PayoutTable = ({ payoutData }) => {
  const param = useParams();
  const navigate = useNavigate();
  const [payoutModal, setPayoutModal] = useState(false);
  const [payoutUserTransections, setPayoutUserTransections] = useState();
  const handlePrice = (cell, row) => {
    console.log("owe", row.calculations[0].ows);
    return <div className="text-success">+{row.calculations[0].ows}</div>;
  };

  console.log("first", param);

  /**
   * Transection list Modal Open
   * @param {*Owes User Name} cell
   * @param {*Owes User List} row
   * @returns
   */
  const openUser = (cell, row) => {
    return (
      <h6
        onClick={() => {
          // setPayoutUserTransections(row);
          // setPayoutModal(true);
          navigate(`/events/payout/user`, {
            state: { userid: row.user_id, eventid: param.eventid },
          });
        }}
        className="event-title-open"
      >
        {cell}
      </h6>
    );
  };
  const oweUser = (cell, row) => {
    return (
      <h6
        onClick={() => {
          navigate(`/events/payout/user`, {
            state: {
              userid: row.calculations[0].user_id,
              eventid: param.eventid,
            },
          });
        }}
        className="event-title-open"
      >
        {row.calculations[0].full_name}
      </h6>
    );
  };

  const columns = [
    {
      headerStyle: (colum, colIndex) => {
        return { width: "10%", ...headerStyle };
      },
      dataField: "secquenceNo",
      text: "Sr no",
      // formatter: (cell, row, rowIndex, formatExtraData) => {
      //   return rowIndex + 1;
      // },
      searchable: false,
    },
    {
      headerStyle: (colum, colIndex) => {
        return { width: "30%", ...headerStyle };
      },
      dataField: "full_name",
      text: "User Name",
      // sort: true,
      formatter: openUser,
    },
    {
      headerStyle: (colum, colIndex) => {
        return { width: "30%", ...headerStyle };
      },
      // dataField: "email",
      text: " Owes User",
      formatter: oweUser,
      // sort: true,
    },
    {
      headerStyle: (colum, colIndex) => {
        return { ...headerStyle };
      },
      dataField: "net_amount",
      text: " Amount",
      // sort: true,
      formatter: handlePrice,
    },
  ];

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ display: "block" }}
      >
        {text}
      </a>
    </li>
  );

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing users {from} to {to} of {size}
    </span>
  );

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "#df6053";
      activeStyle.color = "white";
      activeStyle.borderRadius = "25px";
      activeStyle.fontSize = "14px";
      activeStyle.margin = "2px";
    } else {
      activeStyle.backgroundColor = "#fff";
      activeStyle.color = "black";
      activeStyle.borderRadius = "25px";
      activeStyle.fontSize = "14px";
      activeStyle.margin = "2px";
      activeStyle.border = "none";
    }
    return (
      <li className="page-item">
        <a
          href="#"
          onClick={handleClick}
          style={activeStyle}
          className="page-link"
        >
          {page}
        </a>
      </li>
    );
  };

  const options = {
    custom: true,
    sizePerPageOptionRenderer,
    pageButtonRenderer,
    paginationTotalRenderer: customTotal,
  };

  /**
   * Table
   * @param {pagination} param0
   * @returns
   */
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="event_id"
        columns={columns}
        data={payoutData}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-search-bar">
              <div className="total-user-table ">
                Show
                <SizePerPageDropdownStandalone {...paginationProps} />
                entries
              </div>
              {/* <div className="">
                <SearchBar {...toolkitprops.searchProps} />
              </div> */}
            </div>
            <div className="react-event-table-class">
              <BootstrapTable
                striped
                hover
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                // loading={true}
                sort={{ dataField: "event_id", order: "asc" }}
                // overlay={overlayFactory({
                //   spinner: true,
                //   background: "rgba(192,192,192,10.0)",
                // })}
                rowClasses="table-bg-col"
                wrapperClasses="table-border"
                // remote={{ pagination: true, filter: true }}
                // onTableChange={onTableChange}
              />
            </div>
            {/* Showing events 1 to 10 of 70 */}
            <div className="pagination-event">
              <div>
                <PaginationTotalStandalone {...paginationProps} />
              </div>

              {/* pagignation 1 , 2 , 3 , 4 */}
              <div className="pagination-no">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
      {payoutModal && (
        <PayoutModal
          show={payoutModal}
          closeModal={() => setPayoutModal(false)}
          payoutUserTransections={payoutUserTransections}
        />
      )}
    </>
  );
};

export default PayoutTable;
