import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  searchEvent,
  sortingEvent,
  tableAppFilter,
  tablePageEvent,
  tableSelectFilter,
  tableSizePerPageEvent,
} from "../../../Redux/Action/Events";
import { useNavigate } from "react-router-dom";
import "./eventTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import sort from "../../../assets/images/sortIcon.svg";
import {
  pageSizeOptions,
  statusOptions,
} from "../../../Services/Constant/Constant";

export const EventTable = ({ Events, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventTable = useSelector((store) => store.Events);
  const [sortOrder, setSortOrder] = useState(
    eventTable.sorting.order ? eventTable.sorting.order : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    eventTable.sorting.dataField ? eventTable.sorting.dataField : "e.created_at"
  );
  const search = eventTable.searchText;
  const selectStatus = eventTable.select;
  const selectAppType = eventTable.app;
  const debounceDelay = 1000;
  const tableRef = React.createRef();

  // Function to handle table row click
  const handleRowClick = (eventId) => {
    console.log("Saving scroll pos" + tableRef.current.scrollTop);

    localStorage.setItem("eventTableScrollPosition", tableRef.current.scrollTop);
    // Navigate to the event detail page
    navigate(`event/${eventId}`);
  };

  useEffect(() => {
    // Retrieve the scroll position from local storage
    const storedScrollPosition = localStorage.getItem("eventTableScrollPosition");
    if (storedScrollPosition && tableRef.current) {
      tableRef.current.scrollTo(0, parseInt(storedScrollPosition));
      localStorage.setItem("eventTableScrollPosition", 0);
    }
  }, []);

  const handlePageChange = (newPage) => {
    dispatch(tablePageEvent(newPage));
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    dispatch(tableSizePerPageEvent(newSize));
    dispatch(tablePageEvent(1));
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedHandleSearch = debounce((searchValue) => {
    dispatch(tablePageEvent(1));
    dispatch(searchEvent(searchValue));
  }, debounceDelay);

  const handleSearch = (event) => {
    const newSearchText = event.target.value;
    debouncedHandleSearch(newSearchText);
  };

  const handleSortChange = (column) => {
    setSortColumn(column);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    dispatch(
      sortingEvent({
        dataField: column,
        order: sortOrder === "asc" ? "desc" : "asc",
      })
    );
  };

  const totalEvents = Events && Events.data.totalRecords * 1;
  const totalPages = Math.ceil(totalEvents / eventTable.sizePerPage);

  const paginationItems = [];
  const maxPageCount = 5; // Maximum number of pagination items to show

  const convertToCST = (dateString, options = {}) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { timeZone: "America/Chicago", ...options });
  };

  let startPage, endPage;

  if (totalEvents > 50) {
    // Calculate the range of pages to display around the current page
    startPage = Math.max(eventTable.page - Math.floor(maxPageCount / 2), 1);
    endPage = Math.min(startPage + maxPageCount - 1, totalPages);

    if (endPage - startPage < maxPageCount - 1) {
      startPage = Math.max(endPage - maxPageCount + 1, 1);
    }
  } else {
    // If totalEvents is less than or equal to 50, show all pages
    startPage = 1;
    endPage = totalPages;
  }

  for (let page = startPage; page <= endPage; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === eventTable.page}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const handleStatus = (event) => {
    dispatch(tableSelectFilter(event.target.value));
    dispatch(tablePageEvent(1));
  };

  const appTypeOptions = [
    { label: "All", value: "" },
    { label: "Wedding Bets", value: 1 },
    { label: "Guess The Baby", value: 2 },
  ];

  const handleAppType = (event) => {
    dispatch(tableAppFilter(event.target.value));
    dispatch(tablePageEvent(1));
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="event-total-user-table ">
          <label>Show</label>
          <select
            onChange={handlePageSizeChange}
            className="form-control d-inline-block page-drop-down"
            defaultValue={eventTable.sizePerPage}
          >
            {pageSizeOptions.map((size) => (
              <option
                key={size}
                value={size}
                style={{ backgroundColor: "white", color: "black" }}
              >
                {size}
              </option>
            ))}
          </select>
          entries
        </div>
        <div className="d-flex">
          <div className="event_status-class">
            <label>Status</label>
            <select
              className="form-select"
              onChange={handleStatus}
              defaultValue={selectStatus}
            >
              {statusOptions.map((opt, index) => (
                <option key={index + 100} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
          <div className="event_status-class">
            <label>Type</label>
            <select
              className="form-select"
              onChange={handleAppType}
              defaultValue={selectAppType}
            >
              {appTypeOptions.map((opt, index) => (
                <option key={index} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input
              className="form-control"
              type="text"
              placeholder="Search"
              onChange={handleSearch}
              defaultValue={search}
            />
          </div>
        </div>
      </div>

      <div className="react-event-table mt-3" ref={tableRef}>
        <Table bordered hover>
          <thead>
            <tr>
              <th onClick={() => handleSortChange("event_title")}>
                <label className="header-title-class">Event Title</label>
                <span className="ml-1">
                  {sortColumn === "event_title" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th>
                <label className="header-title-class">Event Location</label>
              </th>
              <th onClick={() => handleSortChange("e.created_at")}>
                <label className="header-title-class">Created Date</label>
                <span className="ml-1">
                  {sortColumn === "e.created_at" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th onClick={() => handleSortChange("e.event_date")}>
                <label className="header-title-class">Event Date</label>
                <span className="ml-1">
                  {sortColumn === "e.event_date" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th onClick={() => handleSortChange("no_of_questions")}>
                <label className="header-title-class">Questions</label>
                <span className="ml-1">
                  {sortColumn === "no_of_questions" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th onClick={() => handleSortChange("no_of_participants")}>
                <label className="header-title-class">Participants</label>
                <span className="ml-1">
                  {sortColumn === "no_of_participants" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th>
                <label>Status</label>
              </th>
              <th onClick={() => handleSortChange("is_prepaid")}>
                <label className="header-title-class">Is Prepaid?</label>
                <span className="ml-1">
                  {sortColumn === "is_prepaid" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
              <th onClick={() => handleSortChange("app_type")}>
                <label className="header-title-class">App Type</label>
                <span className="ml-1">
                  {sortColumn === "app_type" ? (
                    <FontAwesomeIcon
                      className="sort-icon"
                      icon={sortOrder === "asc" ? faArrowDown : faArrowUp}
                    />
                  ) : (
                    <img className="sort-icon" src={sort} alt="sort" />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Events.data.responseData.length > 0 ? (
              loading ? (
                <tr className="data-title-class">
                  <td colSpan="6" className="text-center">
                    <Spinner animation="border" role="status" color="#142567">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : (
                Events.data.responseData.map((event_data, index) => (
                  <tr key={index}>
                    <td
                      className="event-title-open"
                      onClick={() => handleRowClick(event_data?.event_id)}
                    >
                      <label className="title-label-class">{event_data.event_title}</label>
                    </td>
                    <td>{event_data.event_location}</td>
                    <td>{convertToCST(event_data.created_at, { hour12: true })}</td>
                    <td>{convertToCST(`${event_data.event_date} 00:00:00`, { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                    <td>{event_data.no_of_questions}</td>
                    <td>{event_data.no_of_participants}</td>
                    <td>{event_data.status}</td>
                    <td>{event_data.is_prepaid?.toString()}</td>
                    <td>{event_data.app_type}</td>
                  </tr>
                ))
              )
            ) : (
              <tr className="data-title-class">
                <td colSpan="6" className="text-center">
                  No Result Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <div>
          <span>
            {totalEvents === 0
              ? "No events to display"
              : `Showing events ${(eventTable.page - 1) * eventTable.sizePerPage + 1
              } to ${Math.min(
                eventTable.sizePerPage * eventTable.page,
                totalEvents
              )} of ${totalEvents}`}
          </span>
        </div>

        <div>
          <Pagination>
            {eventTable.page > 1 && (
              <Pagination.Prev
                onClick={() => handlePageChange(eventTable.page - 1)}
              >
                Previous
              </Pagination.Prev>
            )}
            {paginationItems}
            {eventTable.page < totalPages && (
              <Pagination.Next
                onClick={() => handlePageChange(eventTable.page + 1)}
              >
                Next
              </Pagination.Next>
            )}
          </Pagination>
        </div>
      </div>
    </div>
  );
};
