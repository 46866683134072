import React from "react";
import { useRouteError } from "react-router-dom";

const errorPage = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
};
const RouterErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div style={errorPage}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default RouterErrorPage;
