import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import "./Header.css";
const Header = ({ toggle }) => {
  const navigate = useNavigate();
  const avtar =
    localStorage.getItem("profile") &&
    JSON.parse(localStorage.getItem("profile")).image_url;
  const logOut = () => {
    console.log("logOut");
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <span className="me-3 navbar-toggler-icon" onClick={toggle}></span>
          <Navbar.Brand href="#home">Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <img
              src={avtar}
              className="rounded-circle shadow-4"
              style={{ width: "35px", height: "35px", objectFit: "cover"}}
              alt="Avatar"
            />
            <Nav className="">
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                {/* <NavDropdown.Item>Profile</NavDropdown.Item> */}
                <NavDropdown.Item onClick={logOut}>
                  <i className="ti-layout-sidebar-left"></i>
                  <span>Logout</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
