import React from "react";

const Loader = () => {
  console.log("Loading");
  return (
    <>
      <div className="text-center center-loader loader-spinner">
        <div className="spinner-border " role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
