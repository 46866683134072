import Api from "../Api/Api";

export const getPreDefinedQuestions = () => {
  return Api.get(`admin/getPredefinedQuestions`);
};

export const addQuestion = (data) => {
  return Api.post(`admin/addPredefinedQuestions`, [data]);
};

export const updateQuestion = (data) => {
  return Api.post(`admin/updatePredefinedQuestions`, data);
};

export const deletePreQuestion = (questionid) => {
  return Api.delete(
    `admin/deletePredefinedQuestions?predefined_question_id=${questionid}`
  );
};

export const dragDropQuestion = (data)=>{
  return Api.put('admin/updatePredefinedQuestionSequence',data)
}
