import React, { useState } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import Header from "../../Layouts/Header";
import Loader from "../../Layouts/Loader";
import SideHeader from "../../Layouts/SideHeader";

const Dashboard = () => {
  const navigation = useNavigation();
  const [toggleOpen, setToggleOpen] = useState(true);

  const toggle = () => {
    console.log("toggle clicked");
    setToggleOpen((toggleStatus) => !toggleStatus);
  };
  return (
    <>
      <Header toggle={toggle} />
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper ">
          <div>{toggleOpen && <SideHeader />}</div>

          <div
            className={toggleOpen ? "pcoded-content" : "pcoded-content-full"}
          >
            <div className="pcoded-inner-content">
              {navigation.state === "loading" ? <Loader /> : <Outlet />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
