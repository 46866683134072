const initialstate = {
  questions: { status: 0 },
  status: 0,
};

const Question = (state = initialstate, action) => {
  switch (action.type) {
    case "Set_All_Pre_Questions":
      return {
        ...state,
        questions: action.payload.data.data,
        status: action.payload.data.status,
      };

    case "Delete_Pre_Questions":
      const filteredQuestions = {
        ...state.questions,
        responseData: state.questions.responseData.filter(
          (el) => el.predefined_question_id !== action.payload.data
        ),
      };
      return {
        ...state,
        questions: filteredQuestions,
      };

    case "Add_Pre_Questions":
      const newQuestions = {
        ...state.questions,
        responseData: [...state.questions.responseData, action.payload.data],
      };

      return {
        ...state,
        questions: newQuestions,
      };

    case "Update_Pre_Questions":
      const updatedQuestions = {
        ...state.questions,
        responseData: state.questions.responseData.map((el) => {
          if (
            el.predefined_question_id !==
            action.payload.data.predefined_question_id
          )
            return el;
          else if (
            el.predefined_question_id ===
            action.payload.data.predefined_question_id
          )
            return action.payload.data;
        }),
      };

      return {
        ...state,
        questions: updatedQuestions,
      };

    default:
      return state;
  }
};

export default Question;
