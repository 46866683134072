const initialstate = {
  searchText: "",
  page: 1,
  sizePerPage: 10,
};

const Users = (state = initialstate, action) => {
  switch (action.type) {
    case "Set_Search_Text":
      return {
        ...state,
        searchText: action.payload.data,
      };

    case "Set_Table_Page_User":
      let pageNo = state.page;
      if (action.payload.data === "Next") {
        pageNo++;
      } else if (action.payload.data === "Previous") {
        pageNo--;
      } else {
        pageNo = action.payload.data;
      }
      return {
        ...state,
        page: pageNo,
      };

    case "Set_Table_SizePerPage_User":
      return {
        ...state,
        sizePerPage: action.payload.data,
      };

    default:
      return state;
  }
};

export default Users;
