import React from "react";
import Modal from "react-bootstrap/Modal";

const PayoutModal = (props) => {
  const { show, closeModal, payoutUserTransections } = props;
  return (
    <>
      <Modal
        // size="lg"
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center d-block">
          <Modal.Title className="text-success ">
            <h2>+${payoutUserTransections.net_amount}</h2>
          </Modal.Title>
          <h5 className="m-0">{payoutUserTransections.full_name}</h5>
          <h6 className="text-secondary">{payoutUserTransections.email}</h6>
        </Modal.Header>
        <Modal.Header className=" d-block">
          <div className="ms-3 me-3 d-flex justify-content-between">
            <p className="mb-0 text-secondary ">Owe List</p>
            <p className="mb-0 text-secondary">Owes Amount</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* --------Owes List------ */}
          {payoutUserTransections.calculations.map((user) => {
            return (
              <div className="m-3 d-flex justify-content-between">
                <div className="d-flex">
                  <div className="avtar text-center">
                    <p className="text-secondary p-2">
                      {user.full_name
                        .split(" ")
                        .splice(0, 2)
                        .map((el) => el[0])}
                    </p>
                  </div>
                  <div className="user-name">
                    <p className="para">{user.full_name}</p>
                    <p className="user-email para">{user.email}</p>
                  </div>
                </div>
                <div className="">
                  <p className="text-danger">
                    +${parseFloat(user.ows).toFixed(2)}
                  </p>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light" onClick={closeModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PayoutModal;
