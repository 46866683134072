import React, { useState } from "react";
import { disableUser, enableUser } from "../../../Services/Users";
import { toast } from "react-toastify";
import UserStatusChange from "../../../Layouts/PopUpModal/UserStatusChange";

const StatusFormatter = ({ cell, row }) => {
  const [userStatusModal, setUserStatusModal] = useState(false);

  const [status, setStatus] = useState(!cell);
  const notify = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const userStatusChange = () => {
    if (status) {
      disableUser({ user_id: row.user_id })
        .then((res) => {
          console.log(res);
          setStatus((data) => !data);
          notify(res.data.message);
          setUserStatusModal(false);
        })
        .catch((err) => {
          console.log(err);
          notifyError(err.response.data.message);
          setUserStatusModal(false);
        });
    } else {
      enableUser({ user_id: row.user_id })
        .then((res) => {
          console.log(res);
          setStatus((data) => !data);
          notify(res.data.message);
          setUserStatusModal(false);
        })
        .catch((err) => {
          console.log(err);
          notifyError(err.response.data.message);
          setUserStatusModal(false);
        });
    }
  };
  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          value={status}
          checked={status}
          onChange={() => setUserStatusModal(true)}
        />
        <div className="slider"></div>
      </label>
      {userStatusModal && (
        <UserStatusChange
          status={status}
          show={userStatusModal}
          handleClose={() => setUserStatusModal(false)}
          deleteQuestion={userStatusChange}
        />
      )}
    </>
  );
};

export default StatusFormatter;
