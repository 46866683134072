import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth } from "./Login/Auth";
import { Pages } from "./Pages/Pages";

const Routes = () => {
  const router = createBrowserRouter([Auth, Pages]);
  return <RouterProvider router={router} />;
};

export default Routes;
