export const setAllPreQuestions = (data) => {
  return {
    type: "Set_All_Pre_Questions",
    payload: {
      data,
    },
  };
};

export const deletePreQuestions = (data) => {
  return {
    type: "Delete_Pre_Questions",
    payload: {
      data,
    },
  };
};

export const addPreQuestions = (data) => {
  return {
    type: "Add_Pre_Questions",
    payload: {
      data,
    },
  };
};

export const updatePreQuestions = (data) => {
  return {
    type: "Update_Pre_Questions",
    payload: {
      data,
    },
  };
};
