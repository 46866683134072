import Api from "../Api/Api";

export const getEvents = (search,page,page_size,sort_by,event_status,app_type) => {
  return Api.get(`event/getEvents?search=${search}&page_no=${page}&page_size=${page_size}&sort_by=${sort_by}&event_status=${event_status}&app_type=${app_type}`);
};

export const getEventDetails = (data) => {
  return Api.get(`event/getEvents?event_id=${data}`);
};

export const getEventQuestions = (data) => {
  return Api.get(`event/getQuestions?event_id=${data}`);
};

export const getEventPayout = (data) => {
  return Api.get(`admin/getEventPayout?event_id=${data}`);
};

export const openAllBets = (data) => {
  return Api.post(`admin/openAllBets?event_id=${data}`);
}

export const uncallAllBetsAndOpenEvent = (data) => {
  return Api.post(`admin/uncallAllBetsAndOpenEvent?event_id=${data}`);
}