import React from "react";
import RouterErrorPage from "../../Layouts/Router/RouterErrorPage";
import Login from "../../Auth/Login";
import Protected from "./Protected";

export const Auth = {
  element: <Protected />,
  errorElement: <RouterErrorPage />,
  children: [
    {
      path: "/",
      element: <Login />,
      errorElement: <RouterErrorPage />,
    },
  ],
};
