import React from "react";
import { Link, useLocation } from "react-router-dom";
import UserProfile from "../../Users/Profile/UserProfile";

const PayoutUserDetails = () => {
  const { state } = useLocation();
  return (
    <>
      <div class="row">
        <div class="col">
          <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                {" "}
                <Link to={"/events"} className="breadcrump-link">
                  Events
                </Link>
              </li>
              <li class="breadcrumb-item">
                <Link
                  to={`/events/event/${state.eventid}`}
                  className="breadcrump-link"
                >
                  Details
                </Link>
              </li>
              <li class="breadcrumb-item">
                <Link
                  to={`/events/payout/${state.eventid}`}
                  className="breadcrump-link"
                >
                  Payout
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                User
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <UserProfile userid={state.userid} />
    </>
  );
};

export default PayoutUserDetails;
