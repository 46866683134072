import React from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { addQuestion } from "../../../Services/PreQuestion";
import { useDispatch } from "react-redux";
import { addPreQuestions } from "../../../Redux/Action/Question";

const AddQuestionForm = ({ QuestionModal, closeQuestionModal }) => {
  const dispatch = useDispatch();
  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);
  const initialValues = {
    question: "",
    choices: [
      {
        choice: "",
        sequence_no: 1,
      },
      {
        choice: "",
        sequence_no: 2,
      },
    ],
    app_type: 2,
  };
  const validationSchema = Yup.object({
    question: Yup.string()
      .required("Please enter your question")
      // .min(10, "Min 10 characters long")
      .max(200, "Maximum 200 characters long"),
    app_type: Yup.number().required("Please select app type"),
    choices: Yup.array().of(
      Yup.object().shape({
        choice: Yup.string()
          .required("Please enter your choice")
          .max(40, "Maximum 40 characters long")
          .test("regex", "Minimum 1 characters long", (val) => {
            let regExp = new RegExp("[a-zA-Z_0-9]+");
            return regExp.test(val);
          }),
      })
    ),
  });
  const onSubmit = (values, { setSubmitting }) => {
    addQuestion(values)
      .then((res) => {
        console.log(res);
        // redirect(-1);
        dispatch(addPreQuestions(res.data.data.responseData[0]));
        notifySuccess(res.data.message);
        closeQuestionModal();
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.response.data.message);
      });
  };
  return (
    <>
      <Modal
        // size="lg"
        show={QuestionModal}
        onHide={closeQuestionModal}
        aria-labelledby="example-modal-sizes-title-lg"
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Question
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-body">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <>
                    <Form className="md-float-material form-material">
                      <div className="p-4">
                        <div class="form-group">
                          <label for="inputQuestion" className="label">
                            Question
                          </label>
                          <Field
                            name="question"
                            type="textarea"
                            className=" form-control question-form-control"
                            as="textarea"
                            id="inputQuestion"
                          />
                          <div className="error-message">
                            <ErrorMessage
                              name="question"
                              className="form-text text-muted"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="app_type" className="label">
                            App Type
                          </label>
                          <Field
                            name="app_type"
                            as="select"
                            className="form-control"
                            id="app_type"
                          >
                            <option value="1">Wedding Bets</option>
                            <option value="2">Guess The Baby</option>
                          </Field>
                          <div className="error-message">
                            <ErrorMessage
                              name="app_type"
                              className="form-text text-muted"
                            />
                          </div>
                        </div>
                        <div>
                          <FieldArray
                            name="choices"
                            render={(arrayHelpers) => (
                              <>
                                {formik.values.choices.map((option, index) => (
                                  <div class="form-group ">
                                    <label
                                      className="label"
                                      for="inputChoice"
                                    >{`Option ${index + 1}`}</label>
                                    <Field
                                      type="text"
                                      name={`choices.${index}.choice`}
                                      as="textarea"
                                      className=" form-control"
                                      id="inputChoice"
                                    />
                                    <div className="error-message">
                                      <ErrorMessage
                                        name={`choices.${index}.choice`}
                                        className="form-text text-muted"
                                      />
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <Modal.Footer>
                        <button
                          className="btn btn-light"
                          onClick={closeQuestionModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn que-btn"
                          type="submit"
                          disabled={formik.isSubmitting}
                        // onClick={closeQuestionModal}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddQuestionForm;
