import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllPreQuestions } from "../../Redux/Action/Question";
import {
  dragDropQuestion,
  getPreDefinedQuestions,
} from "../../Services/PreQuestion";
import AddQuestionForm from "./Components/AddQuestionForm";
import PreQuestionList from "./Components/PreQuestionList";
import Loader from "../../Layouts/Loader/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PreQuestions = () => {
  const ReduxPreQuestion = useSelector((store) => store.Question);
  const [QuestionModal, setQuestionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getPreDefinedQuestions()
      .then((res) => {
        console.log("response", res.data.data.responseData);
        dispatch(setAllPreQuestions(res.data));
        setLoading(false);
      })
      .catch((err) => err.response.data);
  }, []);

  const handleDragEnd = (result) => {
    console.log("result", result);
    if (!result.destination) {
      // If dropped outside the droppable area
      return;
    }
    const items = Array.from(ReduxPreQuestion.questions.responseData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const responseData = {
      status: 1,
      message: "",
      data: {
        responseData: items,
      },
      totalRecords: items.length - 1,
    };
    dispatch(setAllPreQuestions(responseData));
    const updatedData = items.map((item, index) => ({
      predefined_question_id: item.predefined_question_id,
      sequence_no: item.sequence_no,
      new_sequence_no: index + 1,
    }));
    const request_data = {
      question_data: updatedData,
    };
    setSequences(request_data);
  };

  const setSequences = (request_data) => {
    dragDropQuestion(request_data)
      .then((response) => {
        console.log("response", response);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="page_header ">
          <h2 className="mb-0">Predefined Questions</h2>
        </div>
        <button
          button
          type="button"
          onClick={() => setQuestionModal(true)}
          className="btn que-btn  m-1"
        >
          Add New Question
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : ReduxPreQuestion.status ? (
        // <div className="draggable-class">
        //   {ReduxPreQuestion.questions.responseData.map((question, index) => (
        //     <PreQuestionList question={question} index={index} />
        //   ))}
        // </div>
        <div className="draggable-class">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="preQuestions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {ReduxPreQuestion.questions.responseData.map(
                    (question, index) => (
                      <Draggable
                        key={question.predefined_question_id}
                        draggableId={`question-${question.predefined_question_id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <PreQuestionList
                              question={question}
                              index={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="card">
          <h5 className="text-center p-3">Questions does not exist!</h5>
        </div>
      )}
      {QuestionModal && (
        <AddQuestionForm
          QuestionModal={QuestionModal}
          closeQuestionModal={() => setQuestionModal(false)}
        />
      )}
    </>
  );
};

export default PreQuestions;
