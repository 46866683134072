import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Layouts/Loader";
import { getEventDetails, getEventQuestions, openAllBets, uncallAllBetsAndOpenEvent } from "../../../Services/Events";
import EventDetailsCard from "../Components/EventDetailsCard";
import Question from "../Components/Question";

const Details = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState();
  const [eventQuestions, setEventQuestions] = useState();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getEventDetails(param.eventid)
      .then((res) => {
        console.log(res);
        setEventDetails(res.data.data.responseData[0]);
      })
      .catch((err) => console.log(err));

    getEventQuestions(param.eventid)
      .then((res) => {
        console.log(res);
        setEventQuestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleOpenAllBets = () => {
    if (window.confirm("Are you sure you want to open all bets?")) {
      openAllBets(param.eventid).then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    }
  };

  const handleUncallAllBetsAndOpenEvent = () => {
    if (window.confirm("Are you sure you want to uncall all bets and open the event?")) {
      uncallAllBetsAndOpenEvent(param.eventid).then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div class="row">
        <div class="col">
          <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <Link to={"/events"} className="breadcrump-link">
                  Events
                </Link>
              </li>
              <li class="breadcrumb-item">Details</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className="page_header">
          <h2>Event Details</h2>
        </div>
        <button
          button
          type="button"
          onClick={() => navigate(`/events/payout/${param.eventid}`)}
          className="btn m-1"
          style={{ backgroundColor: "#df6053", color: "white" }}
        >
          View Payouts
        </button>
        <button
          button
          type="button"
          onClick={() => handleUncallAllBetsAndOpenEvent() }
          className="btn m-1"
          style={{ backgroundColor: "#FF0000", color: "white" }}
        >
          Uncall All Bets + Open Wedding
        </button>
        <button
          button
          type="button"
          onClick={() => handleOpenAllBets() }
          className="btn m-1"
          style={{ backgroundColor: "#FF0000", color: "white" }}
        >
          Open All Bets
        </button>
      </div>

      {eventQuestions?.status ? (
        <>
          {eventDetails ? (
            <EventDetailsCard Details={eventDetails} />
          ) : (
            <Loader />
          )}
          <div className="page_header ">
            <h2>Event Questions</h2>
          </div>
          {eventQuestions.data.totalRecords ? (
            <div className="mb-4 p-3">
              {eventQuestions.data.responseData.map((question, index) => (
                <Question question={question} index={index} />
              ))}
            </div>
          ) : (
            <div className="card mb-4">
              <h5 className="text-center p-5">Questions does not exist!</h5>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Details;
