import React from "react";
import weddinggraphic from "./wedding-graphic.png";
const Theme = () => {
  return (
    <>
      <div
        className="col-lg-7 login_left_panel"
        style={{ background: "#142567" }}
      >
        <div className="text-center login_logo">
          <img src={weddinggraphic} alt="" />
        </div>
      </div>
    </>
  );
};

export default Theme;
