import React from "react";
import Theme from "../../Components/Auth/Theme";
import Loader from "../../Layouts/Loader";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <>
      <section className="login-block">
        <div className="container-fluid">
          <div className="row">
            {/* Theme Image */}
            <Theme />
            {/* Login Form */}
            <LoginForm />
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
