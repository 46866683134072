import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes, { func } from "prop-types";

const PopUpModal = (props) => {
  const { show, handleClose, deleteQuestion } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Question ?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn que-btn" onClick={deleteQuestion}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PopUpModal.propTypes = {
  show: PropTypes.bool,
  setShow: func,
  handleClose: func,
  handleShow: func,
  deleteQuestion: func,
};

export default PopUpModal;
