import React from "react";
import { NavLink } from "react-router-dom";

const SideHeader = () => {
  const activeStyle = {
    backgroundColor: "#DF6053",
    textDecoration: "none",
    width: "100%",
    color: "white",
  };
  return (
    <>
      <nav className="pcoded-navbar" scroll="no">
        <div className="pcoded-inner-navbar main-menu">
          <ul className="pcoded-item pcoded-left-item">
            <li className="">
              <NavLink
                to="/events"
                style={({ isActive }) =>
                  isActive ? activeStyle : { textDecoration: "none" }
                }
              >
                <span className="pcoded-micon">
                  <i className="ti-bar-chart-alt"></i>
                </span>
                <span className="pcoded-mtext">Events</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="users"
                style={({ isActive }) =>
                  isActive ? activeStyle : { textDecoration: "none" }
                }
              >
                <span className="pcoded-micon">
                  <i className="ti-home"></i>
                </span>
                <span className="pcoded-mtext">Users</span>
              </NavLink>
            </li>

            <li className="">
              <NavLink
                to="prequestions"
                style={({ isActive }) =>
                  isActive ? activeStyle : { textDecoration: "none" }
                }
              >
                <span className="pcoded-micon">
                  <i className="ti-bar-chart-alt"></i>
                </span>
                <span className="pcoded-mtext">Pre Questions</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideHeader;
