const initialstate = {
  searchText: "",
  page: 1,
  sizePerPage: 10,
  sorting: "",
  select: "",
};

const Users = (state = initialstate, action) => {
  switch (action.type) {
    case "Sorting_Event":
      return {
        ...state,
        sorting: action.payload.data,
      };
    case "Set_Search_Text_Event":
      return {
        ...state,
        searchText: action.payload.data,
      };

    case "Set_Table_Page_Event":
      let pageNo = state.page;
      if (action.payload.data === "Next") {
        pageNo++;
      } else if (action.payload.data === "Previous") {
        pageNo--;
      } else {
        pageNo = action.payload.data;
      }
      return {
        ...state,
        page: pageNo,
      };

    case "Set_Table_SizePerPage_Event":
      return {
        ...state,
        sizePerPage: action.payload.data,
      };

    case "Set_Table_Select_Filter":
      return {
        ...state,
        select: action.payload.data,
      };
    case "Set_Table_App_Filter":
      return {
        ...state,
        apptype: action.payload.data,
      };
    default:
      return state;
  }
};

export default Users;
