export const searchUser = (data) => {
  return {
    type: "Set_Search_Text",
    payload: {
      data,
    },
  };
};

export const tablePageUser = (data) => {
  return {
    type: "Set_Table_Page_User",
    payload: {
      data,
    },
  };
};

export const tableSizePerPageUser = (data) => {
  return {
    type: "Set_Table_SizePerPage_User",
    payload: {
      data,
    },
  };
};
