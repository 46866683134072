import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useLoaderData, useNavigate } from "react-router-dom";
import { disableUser, enableUser, getUsers } from "../../../Services/Users";
import "./Table.css";
import Loader from "../../../Layouts/Loader";
import StatusFormatter from "./StatusFormatter";
import { headerStyle } from "../../../assets/css/customStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  searchUser,
  tablePageUser,
  tableSizePerPageUser,
} from "../../../Redux/Action/Users";

const Table = ({ Users }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userTable = useSelector((store) => store.Users);
  const { SearchBar, ClearSearchButton } = Search;

  const handleStatus = (cell, row) => {
    return <StatusFormatter cell={cell} row={row} />;
  };

  const openProfile = (cell, row) => {
    return (
      <h6
        onClick={() => navigate(`profile/${row.user_id}`)}
        className="event-title-open"
      >
        {cell}
      </h6>
    );
  };

  const columns = [
    {
      headerStyle: (colum, colIndex) => {
        return { width: "10%", ...headerStyle };
      },
      dataField: "secquenceNo",
      text: "Sr no",
      // formatter: (cell, row, rowIndex, formatExtraData) => {
      //   console.log(formatExtraData);
      //   return rowIndex + 1;
      // },
      searchable: false,
    },
    {
      dataField: "full_name",
      text: "Name",
      headerStyle: (colum, colIndex) => {
        return { width: "30%", ...headerStyle };
      },
      formatter: openProfile,
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: (colum, colIndex) => {
        return { width: "30%", ...headerStyle };
      },
    },
    {
      dataField: "app_type",
      text: "App Type",
      headerStyle: (colum, colIndex) => {
        return { width: "10%", ...headerStyle };
      },
    },
    {
      dataField: "disabled",
      text: "Enable/Disable",
      formatter: handleStatus,
      headerStyle: (colum, colIndex) => {
        return { ...headerStyle };
      },
      searchable: false,
    },
  ];

  // const onTableChange = (type, newState) => {
  //   // dispatch(searchUser(newState.searchText));
  //   // dispatch(tablePageUser(newState.page));
  //   // dispatch(tableSizePerPageUser(newState.sizePerPage));
  //   // setLoading(true);
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 1000);
  //   console.log(type, newState);
  // };

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => {
    return (
      <li key={text} role="presentation" className="dropdown-item">
        <a
          href="#"
          tabIndex="-1"
          role="menuitem"
          data-page={page}
          onMouseDown={(e) => {
            e.preventDefault();
            onSizePerPageChange(page);
            dispatch(tablePageUser(1));
            dispatch(tableSizePerPageUser(page));
          }}
          style={{ display: "block" }}
        >
          {text}
        </a>
      </li>
    );
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing users {from} to {to} of {size}
    </span>
  );

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      dispatch(tablePageUser(page));
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "#df6053";
      activeStyle.color = "white";
      activeStyle.borderRadius = "25px";
      activeStyle.fontSize = "14px";
      activeStyle.margin = "2px";
    } else {
      activeStyle.backgroundColor = "#fff";
      activeStyle.color = "black";
      activeStyle.borderRadius = "25px";
      activeStyle.fontSize = "14px";
      activeStyle.margin = "2px";
      activeStyle.border = "none";
    }
    return (
      <li className="page-item">
        <a
          href="#"
          onClick={handleClick}
          style={activeStyle}
          className="page-link"
        >
          {page}
        </a>
      </li>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => {
    // console.log("sizePerPage", options, onSizePerPageChange);
    // dispatch(tableSizePerPageUser(Number(currSizePerPage)));
    return (
      <div className="btn-group" role="group">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => {
                onSizePerPageChange(option.page);
                dispatch(tableSizePerPageUser(option.page));
              }}
              className={`btn ${
                isSelect ? "pagination_active " : "pagination_inactive"
              }`}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );
  };

  const options = {
    custom: true,
    sizePerPageOptionRenderer,
    paginationTotalRenderer: customTotal,
    pageButtonRenderer,
    // sizePerPageRenderer,
    page: userTable.page,
    // sizePerPage: userTable.sizePerPage,
    // totalSize:userTable.totalSize,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
  };

  const MySearch = ({ search, paginationProps }) => {
    let input;
    const handleClick = (e) => {
      if (paginationProps.page !== 1) paginationProps.onPageChange(1);
      search.onSearch(e.target.value.trim());
    };
    const handleBlur = (e) => {
      setTimeout(() => {
        dispatch(searchUser(e.target.value.trim()));
        dispatch(tablePageUser(paginationProps.page))
      }, 1000);
    };
    return (
      <div className="search-table">
        <input
          className="form-control"
          // value={props.searchText}
          defaultValue={userTable.searchText}
          onChange={handleClick}
          // onBlur={diss}
          // ref={(n) => (input = n)}
          // value={text}
          placeholder="Search"
          type="text"
          ref={(n) => {
            input = n;
          }}
        />
      </div>
    );
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="user_id"
        columns={columns}
        data={Users.data.responseData}
        search={{ defaultSearch: userTable.searchText.value }}
      >
        {(toolkitprops) => (
          <div>
            <div>
              <div className="table-search-bar">
                <div className="total-user-table">
                  Show
                  <SizePerPageDropdownStandalone {...paginationProps} />
                  entries
                </div>
                <MySearch  search={toolkitprops.searchProps}
                  paginationProps={paginationProps} />
                {/* <ClearSearchButton {...toolkitprops.searchProps} /> */}
              </div>
              <div className="react-event-table-class">
                <BootstrapTable
                striped
                hover
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                loading={paginationProps.loadingResult}
                // remote={{ pagination: true }}
                // overlay={overlayFactory({
                //   spinner: <Loader />,
                //   styles: {
                //     overlay: (base) => ({
                //       ...base,
                //       background: "rgba(255, 0, 0, 0.5)",
                //       color: "#1665d8",
                //     }),
                //   },
                // })}
                // onTableChange={onTableChange}
                sort={{ dataField: "user_id", order: "asc" }}
                noDataIndication={"No Result Found"}
                rowClasses="table-bg-col"
                wrapperClasses="table-border"
                
              />
              </div>
              
            </div>
            {/* Showing events 1 to 10 of 70 */}
            <div className="pagination-event">
              <div>
                <PaginationTotalStandalone {...paginationProps} />
              </div>

              {/* pagignation 1 , 2 , 3 , 4 */}
              <div className="pagination-no">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
    </>
  );
};

export default Table;
