export const pageSizeOptions = [10, 25, 30, 50];
export const statusOptions = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Open",
      value: "Ideal",
    },
    {
      label: "Open For Betting",
      value: "OpenForBetting",
    },
    {
      label: "Completed",
      value: "Closed",
    },
  ];