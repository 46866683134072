export const sortingEvent = (data) => {
  return {
    type: "Sorting_Event",
    payload: {
      data,
    },
  };
};

export const searchEvent = (data) => {
  return {
    type: "Set_Search_Text_Event",
    payload: {
      data,
    },
  };
};

export const tablePageEvent = (data) => {
  return {
    type: "Set_Table_Page_Event",
    payload: {
      data,
    },
  };
};

export const tableSizePerPageEvent = (data) => {
  return {
    type: "Set_Table_SizePerPage_Event",
    payload: {
      data,
    },
  };
};

export const tableSelectFilter =(data)=>{
  return{
    type:"Set_Table_Select_Filter",
    payload:{
      data
    }
  }
}

export const tableAppFilter =(data)=>{
  return{
    type:"Set_Table_App_Filter",
    payload:{
      data
    }
  }
}

