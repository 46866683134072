import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { deletePreQuestion, dragDropQuestion } from "../../../Services/PreQuestion";
import PopUpModal from "../../../Layouts/PopUpModal";
import { useDispatch, useSelector } from "react-redux";
import { deletePreQuestions, setAllPreQuestions } from "../../../Redux/Action/Question";
import UpdateQuestion from "./UpdateQuestion";

const PreQuestionList = ({ question, index }) => {
  const dispatch = useDispatch();
  const ReduxPreQuestion = useSelector((store) => store.Question);
  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);
  const [questionDeleteModal, setQuestionDeleteModal] = useState(false);
  const [updateQuestionModal, setUpdateQuestionModal] = useState(false);

  const deleteQuestion = () => {
    deletePreQuestion(question.predefined_question_id)
      .then((res) => {
        console.log(res);
        notifySuccess(res.data.message);
        dispatch(deletePreQuestions(question.predefined_question_id));
        setQuestionDeleteModal(false);
      })
      .catch((err) => {
        console.log(err);
        setQuestionDeleteModal(false);
        notifyError(err.response.data.message);
      });
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-9">
              <h7 className="card-subtitle  mb-2 ">
                App Type: {question.app_type}
              </h7>
              <h6 className="card-title ">
                {index + 1}. {question.question}
              </h6>
              <ol className="pl-ul">
                {question.choices.map((option, index) => (
                  <li key={index}>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {option.choice}
                    </h6>
                  </li>
                ))}
              </ol>
            </div>
            <div className="col-3 text-end">
              <div className="question-btn">
                <a
                  onClick={() => setUpdateQuestionModal(true)}
                  className="btn btn-outline-primary  me-3"
                >
                  Edit
                </a>
                <button
                  className="btn btn-outline-danger "
                  onClick={() => setQuestionDeleteModal(true)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {updateQuestionModal && (
        <UpdateQuestion
          lgShow={updateQuestionModal}
          closeLgShow={() => setUpdateQuestionModal(false)}
          question={question}
        />
      )}

      {questionDeleteModal && (
        <PopUpModal
          show={questionDeleteModal}
          handleClose={() => setQuestionDeleteModal(false)}
          deleteQuestion={deleteQuestion}
        />
      )}
    </>
  );
};

PreQuestionList.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
};

export default PreQuestionList;
