import React, { useEffect, useState } from "react";
import Loader from "../../Layouts/Loader";
import { getUsers } from "../../Services/Users";
import Table from "./Components/Table";

const Users = () => {
  const [Users, setUsers] = useState();

  useEffect(() => {
    getUsers()
      .then((res) => {
        const updatedUsersData = res.data.data.responseData.map(
          (User, index) => {
            User.secquenceNo = index + 1;
            return User;
          }
        );
        setUsers({
          ...res.data,
          data: { ...res.data.data, responseData: updatedUsersData },
        });
      })
      .catch((err) => setUsers(err.response.data));
  }, []);
  return (
    <>
      <div className="page_header ">
        <h2>All Users</h2>
      </div>
      <div className="card p-4 table-card">
        {Users ? <Table Users={Users} /> : <Loader />}
      </div>
    </>
  );
};

export default Users;
