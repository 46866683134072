import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getEventPayout } from "../../../Services/Events";
import Loader from "../../../Layouts/Loader";
import PayoutTable from "./PayoutTable";

const Payout = () => {
  const [eventPayout, setEventPayout] = useState();
  const [loading, setLoading] = useState(true);
  const param = useParams();

  useEffect(() => {
    getPayout();
  }, []);

  /**
   * Fetch PayOut
   */
  const getPayout = () => {
    getEventPayout(param.eventid)
      .then((res) => {
        console.log(res);
        const updatedPayoutsData = res.data.data.responseData.map(
          (payout, index) => {
            payout.secquenceNo = index + 1;
            return payout;
          }
        );
        setEventPayout({
          ...res.data,
          data: { ...res.data.data, responseData: updatedPayoutsData },
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div class="row">
        <div class="col">
          <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                {" "}
                <Link to={"/events"} className="breadcrump-link">
                  Events
                </Link>
              </li>
              <li class="breadcrumb-item">
                <Link
                  to={`/events/event/${param.eventid}`}
                  className="breadcrump-link"
                >
                  Details
                </Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Payout
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <div className="page_header ">
        <h2>Event Payouts</h2>
      </div> */}
      <div className="d-flex justify-content-between mb-4">
        <div className="page_header">
          <h2>Event Payouts</h2>
        </div>
        <a
          // onClick={() => navigate(`/events/payout/${param.eventid}`)}
          href={`${process.env.REACT_APP_BASE_URL}/event/payout/${param.eventid}`}
          className="btn m-1"
          style={{ backgroundColor: "#df6053", color: "white" }}
        >
          Payouts Details
        </a>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="card mb-4">
            {eventPayout?.data.totalRecords ? (
              <div className="card-body ">
                <PayoutTable payoutData={eventPayout.data.responseData} />
              </div>
            ) : (
              <h5 className="text-center p-5">Payout does not exist!</h5>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Payout;
