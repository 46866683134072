import React from "react";

const Question = ({ question, index }) => {
  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
        <h6 className="card-title mb-3">
          {index + 1}. {question.question}
        </h6>
        <ol>
          {question.choices.map((option) => (
            <li>
              <h6 className="card-subtitle mb-2  text-muted">{option.choice}</h6>
            </li>
          ))}
        </ol>
        <p className="card-subtitle text-muted ">
          Correct Answer :-{" "}
          {question.answer ? (
            <span className="text-success">
              {
                question.choices.filter(
                  (el) => el.choice_id === question.answer
                )[0].choice
              }
            </span>
          ) : (
            question.skipped_answer ? "Answer skipped" : "Answer Not Available"
          )}
        </p>
        </div>
      </div>
    </>
  );
};

export default Question;
