import React from "react";
import { Link, useParams } from "react-router-dom";
import UserProfile from "./UserProfile";

const Profile = () => {
  const param = useParams();
  return (
    <>
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb" className="bg-light rounded-3 p-3 mb-4">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to={"/users"} className="breadcrump-link">
                  Users
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Details
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <UserProfile userid={param.userid} />
    </>
  );
};

export default Profile;
