import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import "./style.css";
import { getAdmin } from "../../Services/Auth/Login";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const [InvalidLogin, setInvalidLogin] = useState("");

  //Current Url
  const url = localStorage.getItem("currentLocation") || "/events";

  /**
   * Login Input object
   */
  const initialValues = {
    email: "",
    password: "",
  };

  /**
   * Validation object
   */
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email address.")
      .required("Please enter your email address."),
    password: Yup.string().required("Please enter your password."),
  });

  /**
   * Login API
   * @param {object} values
   */
  const onSubmit = (values, { setSubmitting }) => {
    /**
     * Login Admin
     */
    getAdmin(values)
      .then((res) => {
        console.log(res);
        setDetails(res);
        setSubmitting(false);
        navigate(url);
      })
      .catch((err) => {
        console.log(err);
        setInvalidLogin(err.response.data.message);
        setSubmitting(false);
      });
  };

  const setDetails = (response) => {
    localStorage.setItem("token", response.data.data.responseData[0].token);
    localStorage.setItem(
      "isAdmin",
      response.data.data.responseData[0].user_data.role
    );
    localStorage.setItem(
      "profile",
      JSON.stringify(response.data.data.responseData[0].user_data)
    );
  };

  return (
    <>
      <div className="col-lg-5 login_form">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              console.log("Formik props", formik);
              return (
                <>
                  <Form className="md-float-material form-material">
                    <div className="auth-box">
                      <div className="card-block">
                        <div className="row m-b-20">
                          <div className="col-md-12">
                            <h1 className="">Login to Wedding Admin!</h1>
                            <p className="login_lead_txt">
                              Please enter login to your account to continue.
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <p className="invalid-Login">{InvalidLogin}</p>
                        </div>
                        <div className="form-group form-primary">
                          <label className="float-label">Email Address</label>
                          <Field
                            name="email"
                            type="text"
                            className={
                              formik.values.email
                                ? "form-control fill"
                                : "form-control"
                            }
                            autoComplete="off"
                          />
                          <div className="error-message">
                            <ErrorMessage name="email" />
                          </div>
                        </div>

                        <div className="form-group form-primary">
                          <label className="float-label">Password</label>
                          <Field
                            name="password"
                            type="password"
                            className={
                              formik.values.password
                                ? "form-control fill"
                                : "form-control"
                            }
                            autoComplete="off"
                          />
                          <div className="error-message">
                            <ErrorMessage name="password" />
                          </div>
                        </div>
                        <div className="row m-t-30">
                          <div className="col-md-4 mt-2">
                            <button
                              type="submit"
                              disabled={formik.isSubmitting}
                              className="btn que-btn btn-md btn-block text-center w-100"
                            >
                              Log In
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
