import React from "react";
import RouterErrorPage from "../../Layouts/Router/RouterErrorPage";
import Dashboard from "../../Pages/Dashboard";
import Events from "../../Pages/Events";
import PreQuestions from "../../Pages/PreQuestions";
import Private from "./Private";
import Profile from "../../Pages/Users/Profile";
import Details from "../../Pages/Events/Details";
import Payout from "../../Pages/Events/Payouts";
import Users from "../../Pages/Users";
import { element } from "prop-types";
import PayoutUserDetails from "../../Pages/Events/Payouts/PayoutUserDetails";

export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Dashboard />,
      errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/events",
          element: <Events />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "events/event/:eventid",
          element: <Details />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "events/payout/:eventid",
          element: <Payout />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "events/payout/user",
          element: <PayoutUserDetails />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "users",
          element: <Users />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "users/profile/:userid",
          element: <Profile />,
          errorElement: <RouterErrorPage />,
        },
        {
          path: "prequestions",
          element: <PreQuestions />,
          errorElement: <RouterErrorPage />,
        },
      ],
    },
  ],
};
