import { Outlet, Navigate, useLocation } from "react-router-dom";

const Private = () => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const auth = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin") === "Admin";
  console.log("auth", auth);
  localStorage.setItem("currentLocation", currentUrl);
  return auth && isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default Private;
