import Api from "../Api/Api";

export const getUsers = () => {
  return Api.get(`admin/getAllUsers`);
};
export const getUserDetails = (data) => {
  return Api.get(`admin/getAllUsers?user_id=${data}`);
};

export const disableUser = (data) => {
  return Api.post(`admin/disableUser`, data);
};

export const enableUser = (data) => {
  return Api.post(`admin/enableUser`, data);
};
