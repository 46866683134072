import { combineReducers } from "redux";
import Question from "./Question";
import Users from "./Users";
import Events from "./Events";

const Reducers = combineReducers({
  Question,
  Users,
  Events,
});

export default Reducers;
